<template>
    <div class="paper-detail">
        <el-radio-group v-model="subjectType" size="medium" @change="selectType" class="radioGroup-purple">
            <el-radio-button :label=0>全部</el-radio-button>
            <el-radio-button :label='subjectItem.label'
                             v-for="(subjectItem, subjectIndex) in subjectTypeList" :key="`subjectTypeList_${subjectIndex}`">
                {{subjectItem.name | formatHeadLineType}}
            </el-radio-button>
        </el-radio-group>
        <el-scrollbar class="exerciseDetail-main" :native="false">
            <div class="headline-item" :id="`questionType_${headlineKey}`"
                 v-for="(headlineItem, headlineKey, headlineIndex) in paperDetail" :key="`paperDetail_${headlineIndex}`">
                <div class="headlineType-title">
                    <span class="title">{{headlineIndex + 1 | formatChinese}}、{{headlineKey | formatHeadLineType}}</span>
                    <span class="subtitle">(共{{headlineItem.list.length}}题<span>，共{{getQuestionTypeTotal(headlineItem.list)}}分</span>)</span>
                </div>
                <div class="subject-item" :id="headlineKey + '_' + questionIndex" v-for="(questionItem, questionIndex) in headlineItem.list" :key="`paperDetail_${questionIndex}`">
                    <div class="question-title">
                        <span>{{questionIndex + 1}}.</span>
                        <span class="type">【{{headlineKey | formatHeadLineType}}】</span>
                        <span class="title" v-html="questionItem.title"></span>
                        <span class="score">({{questionItem.score}}分)</span>
                    </div>
                    <div class="answer-box">
<!--                        单选题，判断题-->
<!--                        <template v-if="isSubmit === 0">-->
                            <el-radio-group v-if="headlineKey === 'single_data' || headlineKey === 'judge_data'" v-model="questionItem.student_answer[0]" class="question-answer">
                                <el-radio :label="answerIndex" class="answer-item" v-for="(answerItem, answerIndex) in questionItem.options">
                                    <span>{{answerIndex + 1 | formatNumber}}、</span>{{answerItem.name}}
                                </el-radio>
                            </el-radio-group>
<!--                        </template>-->
<!--                        <template v-if="isSubmit === 1">-->
<!--                            <el-radio-group v-if="headlineKey === 'single_data' || headlineKey === 'judge_data'" :value="questionItem.student_answer[0]" class="question-answer">-->
<!--                                <el-radio :label="answerIndex" class="answer-item" v-for="(answerItem, answerIndex) in questionItem.options">-->
<!--                                    <span>{{answerIndex + 1 | formatNumber}}、</span>{{answerItem.name}}-->
<!--                                </el-radio>-->
<!--                            </el-radio-group>-->
<!--                        </template>-->
<!--                        多选题-->
<!--                        <template v-if="isSubmit === 0">-->
                            <el-checkbox-group v-if="headlineKey === 'multiple_data'" v-model="questionItem.student_answer" class="question-answer">
                                <el-checkbox :label="answerIndex" class="answer-item" v-for="(answerItem, answerIndex) in questionItem.options">
                                    {{answerIndex + 1 | formatNumber}}、{{answerItem.name}}
                                </el-checkbox>
                            </el-checkbox-group>
<!--                        </template>-->
<!--                        <template v-if="isSubmit === 1">-->
<!--                            <el-checkbox-group v-if="headlineKey === 'multiple_data'" :value="questionItem.student_answer" class="question-answer">-->
<!--                                <el-checkbox :label="answerIndex" class="answer-item" v-for="(answerItem, answerIndex) in questionItem.options">-->
<!--                                    {{answerIndex + 1 | formatNumber}}、{{answerItem.name}}-->
<!--                                </el-checkbox>-->
<!--                            </el-checkbox-group>-->
<!--                        </template>-->
<!--                        填空题-->
                        <div class="question-answer flex" v-if="headlineKey === 'fillBlank_data'">
                            <div class="answer-item" style="line-height: 40px">答案：</div>
                            <div class="answer-content">
<!--                                <template v-if="isSubmit === 0">-->
                                    <div class="answer-item" v-for="(answerItem, answerIndex) in questionItem.student_answer">
                                        {{answerIndex + 1 | formatNumber}}、<el-input v-model.trim="answerItem.name" placeholder="请输入内容" style="width: 660px;"></el-input>
                                    </div>
<!--                                </template>-->
<!--                                <template v-if="isSubmit === 1">-->
<!--                                    <div class="answer-item" v-for="(answerItem, answerIndex) in questionItem.student_answer">-->
<!--                                        {{answerIndex + 1 | formatNumber}}、<el-input :value="answerItem.name" placeholder="请输入内容" style="width: 660px;"></el-input>-->
<!--                                    </div>-->
<!--                                </template>-->
                            </div>
                        </div>
<!--                        问答题-->
                        <div class="question-answer flex" v-if="headlineKey === 'askAnswer_data'">
                            <div class="answer-item" style="line-height: 40px">答案：</div>
                            <div class="answer-content">
                                <div class="answer-item">
                                    <el-input type="textarea" resize="none" placeholder="此处作答" :autosize="{ minRows: 4, maxRows: 4}"
                                              v-model="questionItem.student_answer[0]" style="max-height: 200px;width: 100%"></el-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
    export default {
        props: {
            paperDetail: {
                type: Object,
                default:() => {}
            }
        },
        filters: {
            // 题型
            formatTestType(type) {
                const types = {
                    1: '单选题',
                    2: '多选题',
                    3: '判断题',
                    4: '填空题',
                    5: '问答题',
                    6: '操作题',
                }
                return types[type]
            },
            // 题型
            formatHeadLineType(type) {
                const types = {
                    'single_data': '单选题',
                    'multiple_data': '多选题',
                    'judge_data': '判断题',
                    'fillBlank_data': '填空题',
                    'askAnswer_data': '问答题',
                }
                return types[type]
            },
            // 试题答案格式
            formatNumber(nums) {
                const numbers = {
                    '1': 'A',
                    '2': 'B',
                    '3': 'C',
                    '4': 'D',
                    '5': 'E',
                    '6': 'F',
                }
                return numbers[nums]
            },
            // 汉字一二三四五六
            formatChinese(nums) {
                const numbers = {
                    '1': '一',
                    '2': '二',
                    '3': '三',
                    '4': '四',
                    '5': '五',
                    '6': '六',
                    '7': '七',
                    '8': '八',
                }
                return numbers[nums]
            },
        },
        data() {
            return {
                // isSubmit: Number(this.$route.query.isSubmit) || 0,
                subjectType: 0,
                subjectTypeList: [],
            }
        },
        mounted() {
            this.getSubjectTypeList()
        },
        methods: {
            // 计算每道大题总分
            getQuestionTypeTotal(item) {
                let total = 0;
                item.forEach(i => {
                    total += Number(i.score)
                })
                return total
            },
            // 获取题型切换菜单
            getSubjectTypeList() {
                    for (let key in this.paperDetail) {
                        let tmp = {}
                        if (key === 'single_data') {
                            tmp.label = 1
                            tmp.name = 'single_data'
                        } else if (key === 'multiple_data') {
                            tmp.label = 2
                            tmp.name = 'multiple_data'
                        } else if (key === 'judge_data') {
                            tmp.label = 3
                            tmp.name = 'judge_data'
                        } else if (key === 'fillBlank_data') {
                            tmp.label = 4
                            tmp.name = 'fillBlank_data'
                        } else if (key === 'askAnswer_data') {
                            tmp.label = 5
                            tmp.name = 'askAnswer_data'
                        }
                        this.subjectTypeList.push(tmp)
                    }
            },
            // 筛选试题类型
            selectType(val) {
                if (val === 1 || val === 0) {
                    document.querySelector('#questionType_single_data').scrollIntoView(true);
                } else if (val === 2) {
                    document.querySelector('#questionType_multiple_data').scrollIntoView(true);
                } else if (val === 3) {
                    document.querySelector('#questionType_judge_data').scrollIntoView(true);
                } else if (val === 4) {
                    document.querySelector('#questionType_fillBlank_data').scrollIntoView(true);
                } else if (val === 5) {
                    document.querySelector('#questionType_askAnswer_data').scrollIntoView(true);
                } else if (val === 6) {
                    document.querySelector('#questionType_operation').scrollIntoView(true);
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep p {
        margin: 0;
    }
    .paper-detail {
        display: flex;
        flex-direction: column;
        height: 1%;
        flex: 1;
        .exerciseDetail-main {
            height: 1%;
            flex: 1;
            ::v-deep > .el-scrollbar__wrap {
                overflow-x: hidden;
            }
        }
        .headline-item {
            margin-top: 40px;
            &:first-child {
                margin-top: 0;
            }
            .headlineType-title {
                padding-left: 40px;
                margin-bottom: 20px;
                .title {
                    font-size: 18px;
                    font-weight: bold;
                }
                .subtitle {
                    margin-left: 10px;
                    font-size: 18px;
                    font-weight: bold;
                }
            }
        }
        .subject-item {
            font-size: 16px;
            margin-bottom: 20px;
            .question-title {
                background: #F7F7F7;
                padding: 15px 40px;
                .type {
                    font-weight: 500;
                }
                .title {
                    ::v-deep p {
                        display: inline;
                    }
                    ::v-deep img {
                        max-width: 300px!important;
                        max-height: 300px!important;
                    }
                }
                .score {
                    font-weight: 500;
                }
            }
            .answer-box {
                .question-answer {
                    padding: 0 40px;
                    &.el-checkbox-group, &.el-radio-group {
                        display: flex;
                        flex-wrap: wrap;
                    }
                    .answer-item {
                        margin-top: 20px;
                        font-weight: 400;
                        color: #333;
                        &.el-radio {
                            display: block;
                            width: 100%;
                            white-space: normal;
                            line-height: 1.5;
                            margin-right: 0;
                            ::v-deep .el-radio__label {
                                font-size: 16px;
                            }
                        }
                        ::v-deep .el-radio__input {
                            white-space: normal;
                            &.is-checked {
                                & + .el-radio__label {
                                    color: #333;
                                }
                                .el-radio__inner {
                                    border-color: #2DC079;
                                    /*background: #2DC079;*/
                                    background: transparent;
                                    &:after {
                                        width: 5px;
                                        height: 5px;
                                        background-color: #2DC079;
                                    }
                                }
                            }
                            .el-radio__inner {
                                &:hover {
                                    border-color: #2DC079;
                                }
                            }
                        }
                        &.el-checkbox {
                            white-space: normal;
                            line-height: 1.5;
                            display: block;
                            width: 100%;
                            margin-right: 0;
                            ::v-deep .el-checkbox__label {
                                font-size: 16px;
                            }
                        }
                        ::v-deep .el-checkbox__input {
                            white-space: normal;
                            .el-checkbox__label {
                                /*font-size: 16px;*/
                            }
                            &.is-checked {
                                & + .el-checkbox__label {
                                    color: #333;
                                }
                                .el-checkbox__inner {
                                    background-color: #2DC079;
                                    border-color: #2DC079;
                                }
                            }
                            &.is-indeterminate {
                                .el-checkbox__inner {
                                    background-color: #2DC079;
                                    border-color: #2DC079;
                                }
                            }
                            .el-checkbox__inner {
                                &:hover {
                                    border-color: #2DC079;
                                }
                            }
                        }
                    }
                    .answer-content {
                        width: 1%;
                        flex: 1;
                    }
                }
            }
        }
    }


    /*单选框组 样式覆盖*/
    .radioGroup-purple {
        margin: 0 40px 30px;
        ::v-deep .el-radio-button {
            margin-left: 16px;
            .el-radio-button__inner {
                color: #333;
                border: 1px solid #BFBFBF;
                border-radius: 4px;
                padding: 10px 0;
                width: 64px;
                font-weight: normal;
                &:hover {
                    color: #2DC079;
                }
            }
            &:first-child {
                margin-left: 0;
            }
            &:first-child, &:last-child {
                .el-radio-button__inner {
                    border-radius: 0;
                }
            }
            .el-radio-button__orig-radio:checked + .el-radio-button__inner {
                color: #fff;
                background-color: #2DC079;
                border-color: #2DC079;
                box-shadow: -1px 0 0 0 #2DC079;
            }
        }
    }
</style>